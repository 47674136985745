import { render, staticRenderFns } from "./dataCollapse.vue?vue&type=template&id=476787a1&scoped=true&"
import script from "./dataCollapse.vue?vue&type=script&lang=js&"
export * from "./dataCollapse.vue?vue&type=script&lang=js&"
import style0 from "./dataCollapse.vue?vue&type=style&index=0&id=476787a1&lang=less&scoped=true&rel=stylesheet%2Fless&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "476787a1",
  null
  
)

export default component.exports