<!--
 * @Author: 月魂
 * @Date: 2021-05-24 14:39:33
 * @LastEditTime: 2021-05-27 13:20:54
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \stoms-mp\src\views\TicketDetail.vue
-->
<template>
  <div class="box">
    <van-nav-bar
      title="详情"
      fixed
      placeholder
    >
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="jumpHome" />
      </template>
    </van-nav-bar>
    <div class="content">
      <!-- 顶部剧目图文介绍 -->
      <div class="top">
        <div class="left">
          <img src="../assets/1.jpg">
        </div>
        <div class="right">
          <div class="title">这个是剧目名称</div>
          <div class="type">
            <span>童话故事</span>
          </div>
          <div class="price">￥120 - ￥240</div>
          <van-divider />
          <div class="label" @click="showPerform">
            <span>国外经典</span>
            <span>梅花奖</span>
            <van-icon name="arrow" class="arrow" />
          </div>
        </div>
      </div>
      <!-- 权益 -->
      <div class="power" @click="showPerform">
        <div class="power-item">
          <van-icon name="warning" color="#f59a23" />
          <span>不可退票</span>
        </div>
        <div class="power-item">
          <van-icon name="checked" color="#f59a23" />
          <span>支持电子票</span>
        </div>
        <div class="power-item">
          <van-icon name="checked" color="#f59a23" />
          <span>纸质发票</span>
        </div>
        <van-icon name="arrow" class="arrow" />
      </div>
      <!-- 领取电子券 -->
      <div class="discount" @click="showCouponList">
        <div class="title">领取电子券</div>
        <div class="group">
          <div class="item">满200减20</div>
          <div class="item">满500减50</div>
        </div>
        <van-icon name="arrow" class="arrow" />
      </div>
      <!-- 购买会员卡 -->
      <div class="discount" @click="jumpToCardList">
        <div class="title">购买会员卡</div>
        <div class="group">
          <div class="item">388月卡</div>
          <div class="item">588季卡</div>
          <div class="item">1088年卡</div>
        </div>
        <van-icon name="arrow" class="arrow" />
      </div>
      <!-- 演出时间 -->
      <div class="part">
        <div class="title">演出时间</div>
        <div class="part-cont">2021.03.08 - 2021.04.08</div>
        <div class="des">演出约60分钟，仅供参考，以现场实际时长为准</div>
      </div>
      <!-- 演出场馆 -->
      <div class="part">
        <div class="title">演出场馆</div>
        <div class="part-cont">中贝元剧场</div>
        <div class="des">西安市文艺北路197号西安群众艺术馆-1层</div>
      </div>
      <!-- 购票提示 -->
      <div class="part">
        <div class="title">购票提示</div>
        <div class="des">因疫情及疫情防控政策影响，若演出受不可抗力影响延期或取消导致退票的，仅支持退回票款，其它费用需由您自行承担。</div>
      </div>
      <!-- 剧目介绍 -->
      <div class="part">
        <div class="title">剧目介绍</div>
        <!-- <div v-html=""></div> -->
      </div>
      <!-- 购票须知 -->
      <div class="part">
        <div class="title">购票须知</div>
        <DataCollapse :introduce="fir" />
      </div>
      <!-- 观演提示 -->
      <div class="part">
        <div class="title">观演提示</div>
        <DataCollapse :introduce="fir" />
      </div>
      <Collapse />
      <van-goods-action>
        <van-goods-action-button
          type="danger"
          text="立即购买"
          @click="handleBuy"
        />
      </van-goods-action>
    </div>
    <van-action-sheet v-model="show" :title="showData.title">
      <div class="show-cont">
        <ul class="show-ul">
          <li class="show-li" v-for="(item, index) in showData.cont" :key="index">
            <div class="title">{{ item.title }}</div>
            <div class="des">{{ item.des }}</div>
          </li>
        </ul>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="showCoupon" title="优惠券">
      <div class="show-cont">
        <div class="des">说明:</div>
        <div class="des">优惠券具体使用以票面信息为准。实际优惠情况以订单确认页为准。领取优惠券需要先完成用户身份认证。</div>
        <div class="coupon" v-for="item in couponList" :key="item.id">
          <div class="left">
            <div class="price">￥<span class="price-text">{{ item.price }}</span></div>
            <div class="next">
              <div class="top">{{ item.des }}</div>
              <div class="bottom">{{ item.label }}</div>
            </div>
          </div>
          <div class="right">
            <van-button type="danger" class="coupon-btn" v-if="!item.received">立即领取</van-button>
            <van-button type="danger" class="coupon-btn" v-else disabled>已领取</van-button>
          </div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Vue from 'vue';
import Collapse from '../components/Collapse'
import DataCollapse from '../components/Collapse/dataCollapse'
import { NavBar, Icon, Divider, ActionSheet, GoodsAction, GoodsActionButton, Button } from 'vant';
Vue.use(NavBar).use(Icon).use(Divider).use(ActionSheet).use(GoodsAction).use(GoodsActionButton).use(Button);
export default {
  name: 'TicketDetail',
  components: {
    Collapse,
    DataCollapse
  },
  data() {
    return {
      show: false,
      showCoupon: false,
      couponList: [
        {
          id: 1,
          price: 199,
          des: '满200可用',
          label: '全场通用券',
          received: true
        },
         {
          id: 2,
          price: 99,
          des: '满200可用',
          label: '仅限儿童剧可用',
          received: false
        }
      ],
      showData: {
        title: '剧目说明',
        cont: [
          {
            title: '剧目说明1',
            des: '这里是关于标签的一些说明。这里是关于标签的一些说明。这里是关于标签的一些说明。这里是关于标签的一些说明。这里是关于标签的一些说明。这里是关于标签的一些说明。'
          },
           {
            title: '剧目说明2',
            des: '这里是关于标签的一些说明。这里是关于标签的一些说明。这里是关于标签的一些说明。这里是关于标签的一些说明。这里是关于标签的一些说明。这里是关于标签的一些说明。'
          }
        ]
      },
      fir: [
        {
          title: '购票须知',
          cont: '每笔订单最多订购5张'
        },
        {
          title: '入场规则',
          cont: '演出开始前，请提前30分钟持纸质票或电子票检票入场'
        },
        {
          title: '儿童票',
          cont: '观看演出凭票入场，执行一人一票制，小孩需持票入场'
        },
        {
          title: '发票规则',
          cont: '演出结束后请联系我司客服提交发票申请。演出结束后1个月左右为您邮寄，需自付邮费。'
        }
      ]
    }
  },
  methods: {
    jumpHome() {
      this.$router.replace('/')
    },
    showPerform() {
      this.show = true
      this.showData = {
        title: '剧目说明',
        cont: [
          {
            title: '剧目说明1',
            des: '这里是关于标签的一些说明。这里是关于标签的一些说明。这里是关于标签的一些说明。这里是关于标签的一些说明。这里是关于标签的一些说明。这里是关于标签的一些说明。'
          },
           {
            title: '剧目说明2',
            des: '这里是关于标签的一些说明。这里是关于标签的一些说明。这里是关于标签的一些说明。这里是关于标签的一些说明。这里是关于标签的一些说明。这里是关于标签的一些说明。'
          }
        ]
      }
    },
    showCouponList() {
      this.showCoupon = true
    },
    jumpToCardList() {
      this.$router.push('/card')
    },
    handleBuy() {
      console.log('buy')
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100%;
  background-color: #f8f8f8;
  .content {
    padding: 10px 0 50px;
    .top {
      display: flex;
      padding: 0 10px;
      .left {
        width: 40%;
        img {
          width: 100%;
        }
      }
      .right {
        width: 60%;
        padding-left: 10px;
        .title {
          font-size: 16px;
          font-weight: 600;
          padding-bottom: 10px;
          color: #555;
        }
        .type {
          margin-bottom: 10px;
          span {
            box-sizing: border-box;
            font-size: 12px;
            padding: 3px;
            border: 1px solid #D7D7D7;
            color: #7f7f7f;
            border-radius: 5px;
            margin-right: 4px;
          }
        }
        .price {
          color: #E13349;
        }
        .label {
          span {
            box-sizing: border-box;
            font-size: 12px;
            padding: 3px;
            border: 1px solid #D7D7D7;
            color: #7f7f7f;
            border-radius: 5px;
            margin-right: 4px;
          }
          .arrow {
            color: #7f7f7f;
            float: right;
          }
        }
      }
    }
    .power {
      overflow: hidden;
      margin: 10px 0;
      padding: 0 10px;
      .power-item {
        float: left;
        display: flex;
        align-items: center;
        font-size: 14px;
        span {
          margin-left: 2px;
        }
      }
      .power-item + .power-item {
        margin-left: 10px;
      }
      .arrow {
        color: #7f7f7f;
        float: right;
      }
    }
    .discount {
      overflow: hidden;
      padding: 10px 10px;
      background-color: #fff;
      margin-top: 10px;
      font-size: 12px;
      .title {
        float: left;
        color: #ee0a24;
      }
      .group {
        float: left;
        display: flex;
        .item {
          color: #f59a23;
          margin-left: 10px;
        }
      }
      .arrow {
        float: right;
        color: #7f7f7f;
      }
    }
    .part {
      padding: 10px 10px 0;
      line-height: 1.5;
      background-color: #fff;
      margin-top: 10px;
      .title {
        font-size: 18px;
        line-height: 2;
      }
      .part-cont {
        font-size: 14px;
      }
      .des {
        font-size: 12px;
        color: #7F7F7F;
      }
      .notice {
        list-style: disc;
        padding-left: 20px;
        .notice-title {
          font-size: 14px;
          color: #555;
        }
        .notice-cont {
          font-size: 12px;
          color: #7f7f7f;
        }
      }
    }
  }
}
.show-cont {
  padding: 10px 10px 30px;
  .des {
    font-size: 14px;
    color: #7f7f7f;
  }
  .coupon {
    margin-top: 10px;
    display: flex;
    .left {
      width: 70%;
      padding: 10px;
      background-color: #f2f2f2;
      display: flex;
      .price {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 1.5;
        .price-text {
          font-size: 24px;
          color: red;
        }
      }
      .next {
        color: #7f7f7f;
        margin-left: 20px;
        line-height: 1.5;
        .top {
          font-size: 14px;
        }
        .bottom {
          font-size: 12px;
          color: #f59a23;
        }
      }
    }
    .right {
      width: 30%;
      .coupon-btn {
        width: 100%;
        height: 100%;
      }
    }
  }
  .show-ul {
    .show-li {
      list-style-type: none;
      padding-left: 30px;
      background: url('../assets/checked.svg') no-repeat 0 2px;
      background-size: 16px 16px;
      margin-top: 10px;
      .title {
        font-size: 14px;
        margin-bottom: 10px;
      }
      .des {
        font-size: 12px;
        color: #7f7f77;
      }
    }
  }
}
</style>
